
<template>
  <div class="login full_screeen" style="background: #f5f5f5">
    <headerderail :headertitle="headertitle" :backshow="backshow"></headerderail>
    <div class="login-mainview">
      <div class="main-topview" style="margin: 0 20px">
        <img src="../../assets/logo_h.png" alt="" style="margin: auto;display: block;">
      </div>
      <loginForm></loginForm>
    </div>

  </div>
</template>

<script>
  import headerderail from 'cp/v-header/headerdetail.vue'
  import loginForm from 'v/login/login-form.vue'
  export default {
    data () {
      return {
        headertitle: '登录',
        backshow: true,
        logoimg: '../../assets/logo_h.png'
      }
    },
    components: {
      headerderail,
      loginForm
    },
    methods: {
      back () {
          this.$router.go(-1)
      }
    }
  }
 </script>

<style lang="stylus" rel="stylesheet/stylus">
  @import "login.styl"
</style>
