<template>
  <div class="main-logindiv">
    <cube-form :model="userinfo" @submit.prevent="submitHandler">
      <cube-form-group>
        <cube-form-item :field="fields[0]" style="float: left;"></cube-form-item>
        <div class="ivu-row">
          <cube-form-item :field="fields[1]" style="float: left;"></cube-form-item>
          <button class="vcode" @click="getCode" :disabled="!show">
            <span v-show="show" >获取验证码</span>
            <span v-show="!show" class="count">{{count}} s</span>
          </button>
        </div>
      </cube-form-group>
      <cube-form-group>
        <cube-button :primary="true" type="submit">登录</cube-button>
      </cube-form-group>
    </cube-form>
  </div>
</template>

<script>
    import { todoStorage } from 'libs/storage'
    import {
      login,
      getSmsCode
    } from 'api/user'
    export default {
        name: 'login-form',
        data () {
          return {
            show: true,
            count: '',
            userinfo: {
              phone: '',
              captcha: ''
            },
            fields: [
              {
                type: 'input',
                modelKey: 'phone',
                // label: '电话：',
                props: {
                  placeholder: '请输入您的电话',
                  type: 'number'
                }
              },
              {
                type: 'input',
                modelKey: 'captcha',
                // label: '验证码：',
                props: {
                  placeholder: '请输入验证码',
                  type: 'number'
                }
              }
            ]
          }
        },
        methods: {
          submitHandler (e) {
            // this.$router.go(-1)
            let t = this
            if (t.userinfo.phone.length > 0) {
              if (t.userinfo.captcha.length > 0) {
                t.showLoading()
                login(t.userinfo).then(res => {
                  console.log(res)
                  todoStorage.setUserData = res.data
                  todoStorage.setUserIsLogin = true
                  t.hideLoading()
                  t.NoteTips('登录成功')
                  t.$router.push({ name: 'index' })
                }, function (error) {
                  console.log(error)
                  t.hideLoading()
                })
              } else {
                t.NoteTips('请输入验证码')
              }
            } else {
              t.NoteTips('请输入手机号码')
            }
          },
          getCode () {
            let t = this
            if (t.userinfo.phone.length > 0 && t.show) {
              t.showLoading()
              var data = {
                phone: t.userinfo.phone
              }
              const TIME_COUNT = 120
              getSmsCode(data).then(res => {
                console.log(res)
                t.hideLoading()
                if (!t.timer) {
                  t.count = TIME_COUNT
                  t.show = false
                  t.timer = setInterval(() => {
                    if (t.count > 0 && t.count <= TIME_COUNT) {
                      t.count--
                    } else {
                      t.show = true
                      clearInterval(t.timer)
                      t.timer = null
                    }
                  }, 1000)
                }
              }, function (error) {
                console.log(error)
                t.hideLoading()
              })
            } else {
              t.NoteTips('请输入手机号码')
            }
          }
        }
    }
</script>

<style lang="stylus" rel="stylesheet/stylus">
  .main-logindiv
    width 100%
    min-height 200px
    input
      text-align: left;
    button.vcode
      height: 47px;
      float: right;
      width: 80px;
      border:none
</style>
